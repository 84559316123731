import '../estilo/estilo.css';


function NotFound () {
    
    return(
      <div>
        <h1>PÁGINA NÃO ENCONTRADA</h1>
        <p>Volta para a Página anterior e escolha outra opção</p>
      </div>
      )
  }

export default NotFound;